.setup {
  display: flex;
  flex-direction: column;
  width: 100%;

  &-gearList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}