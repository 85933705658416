.login {
  &-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 18em;

    .inputItem {
      display: grid;
      grid-template-columns: 80px 1fr 2fr 80px;
      align-items: center;
      width: 100%;
      margin-top: 0.5rem;

      label {
        align-items: center;
        font-size: 24px;
        grid-column: 2;
      }

      input {
        position: relative;
        margin-left: 3rem;
        grid-column: 3;
      }

      textarea {
        position: relative;
        margin-left: 3rem;
        grid-column: 3;
        font-family: inherit;
      }
    }
    
    button {
      align-self: center;
      justify-self: center;
      margin-top: 1em;

    }

  }

  &-notification {
    font-size: 24px;
    height: 2rem;
    color: #ea0f0f;
    margin-top: 2rem;
  }
}