.home {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &-title {
    text-align: center;
  }

  &-body {
    
  }

}